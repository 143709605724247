<template>
  <div>
   
    <div class="sliderWrap">
          <slider ref="slider" :options="options">
              <!-- <slideritem class='slideritem2'>
                    <div class="container blockInner blockInner2">
                        <img class="img1 animate__animated animate__fadeInDown" src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/banner2.png">
                        <div class="animate__animated animate__fadeInDown">
                            <h2> 国家区块链创新应用试点 </h2>
                            <h4> 中国知链-基于区块链技术的学术论文知识产权<br>保护及认证体系平台 </h4>
                            <div class="btnWrap">  
                              <router-link :to="{path:'/ExistingEvidence/InputForm'}">
                                  <a-button size="large">去存证</a-button>
                              </router-link>
                            </div>
                        </div>
                    </div>
              </slideritem>
              <slideritem>
                    <div class="container blockInner">
                       <img class="img1 animate__animated animate__fadeInDown" src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/banner1.svg">
                        <div class="animate__animated animate__fadeInDown">
                            <h2> 基于区块链技术的知识产权保护 </h2>
                            <h4> 提供免费储存服务、拥有强大的内容服务体系 </h4>
                            <div class="btnWrap">  
                              <router-link :to="{path:'/ExistingEvidence/InputForm'}">
                                  <a-button size="large">存证原创</a-button>
                              </router-link>
                              <router-link :to="{path:'/Search'}">
                                    <a-button type="primary" size="large"> 搜索原创 </a-button>
                              </router-link>
                            </div>
                        </div>
                    </div>
              </slideritem> -->
              <slideritem>
                    <div class="container blockInner">
                       <img class="img1 animate__animated animate__fadeInDown" src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-banner01.png">
                        <div class="animate__animated animate__fadeInDown">
                            <!-- <h2> 国家区块链<br>创新应用试点 </h2>
                            <h4> 中国知链-基于区块链技术的学术论文知识产权<br>保护及认证体系平台 </h4> -->
                            <img class="img2" src="https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/newBanner01.png" alt="">
                            <div class="btnWrap">
                              <router-link :to="{path:'/ExistingEvidence/InputForm'}">
                                  <a-button type="primary" size="large">存证原创</a-button>
                              </router-link>
                              <router-link :to="{path:'/Search'}">
                                    <a-button class="tosearch" size="large"> 搜索原创 </a-button>
                              </router-link>
                            </div>
                        </div>
                    </div>
              </slideritem>
              
              <div slot="loading">loading...</div>
          </slider>
    </div>
    <service-block></service-block>
    <superiority-block></superiority-block>
    <record-block></record-block>
    <!-- 数字报表板块 -->
      <div class="reportView">
        <div class="fluid container">
          <a-row>
            <a-col
              :md="24"
              :lg="12"
              :xl="6"
              v-for="(item,index) in reporList"
              :key="index"
            >
              <div class="block">
                <img :src="'https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/ipChain-icon'+(index+1)+'.png'">
                <div>
                  <div class="tittle">
                    <span
                      class="dot"
                      :class="{'blueDot':item.color=='blue','orangeDot':item.color=='orange','greenDot':item.color=='green'}"
                    > </span>
                    <span> {{item.tittle}} </span>
                  </div>
                  <h3>
                    <ICountUp
                      :delay="delay"
                      :endVal="item.num"
                    />
                    <span class="dan"> {{item.dan}} </span>
                  </h3>

                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    <guarantees-block></guarantees-block>
    
  </div>
</template>

<script>

import ServiceBlock from '@/components/ServiceBlock'
import SuperiorityBlock from '@/components/SuperiorityBlock'
import GuaranteesBlock from '@/components/GuaranteesBlock'
import RecordBlock from '@/components/RecordBlock'
import { slider, slideritem } from 'vue-concise-slider'

import { queryChainSummary } from '@/api/ChainService'
import ICountUp from 'vue-countup-v2'

export default {
  name: 'Home',
  components: {
    ServiceBlock,
    SuperiorityBlock,
    GuaranteesBlock,
    RecordBlock,
    slider,
    slideritem,
    ICountUp
  },
  mounted () {
    this.init()
  },
  data () {
    return {
      //Slider configuration [obj]
      options: {
        currentPage: 0
      },
      reporList: [
        {
          tittle: '最新高度',
          num: 1277390,
          color: 'green'
        },
        {
          tittle: '最新区块时间',
          num: 12,
          dan: '秒前',
          color: 'blue'
        },
        {
          tittle: '区块交易数',
          num: 1211123,
          color: 'orange'
        },
        {
          tittle: '活跃节点数',
          num: 912,
          color: 'orange'
        },
        {
          tittle: '实时TPS',
          num: 0,
          color: 'orange'
        },
        {
          tittle: '未确认交易',
          num: 0,
          color: 'orange'
        },
        {
          tittle: '平均区块时间',
          num: 9,
          dan: '秒',
          color: 'blue'
        },
        {
          tittle: '网络节点',
          num: 14,
          color: 'green'
        },
      ],
      delay: 1000,
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      let that = this;
      queryChainSummary().then(({ data }) => {
        console.log(data);
        let list = that.reporList;
        list[0].num = data.latestBlockHeight * 1;
        list[1].num = Math.round(Date.now() / 1000) - Math.round(data.latestBlockTime);
        list[2].num = data.totalTransactionCount * 1;
        list[3].num = data.activePeerCount * 1;
        list[4].num = data.tps * 1;
        list[5].num = data.unconfirmedTransactionCount * 1;
        list[6].num = 1;
        list[7].num = data.totalPeerCount * 1;
        that = list = list;
      })
      if(this.searchValue){
        // this.searchValue=this.$route.params.transactionHash;
        this.searchCertificate(); 
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.sliderWrap{
  margin-top: -84px;
   background-size: 100%;
   height: 750px;
   /deep/ .slider-pagination-bullet{
     width:56px;
     display: inline-block;
     height: 4px;
     border-radius: 8px;
     background: rgba($color: #fff, $alpha: .20);
   }
  .slider-item{
    background: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/home-bannerBg.png') no-repeat 50% 100%;
    background-size: 100% 100%;
  }
  .slideritem2{
    background: url('https://ipchain.oss-cn-hangzhou.aliyuncs.com/web/privateicon/bannerBg2.png') no-repeat;
    background-size: 100%;
  }
   /deep/ .slider-pagination-bullet-active{
     background: rgba($color: #fff, $alpha: .65);
   }
   .blockInner{
     position: relative;
     display: flex;
     align-items: center;
     text-align: left;
      &>div:first-child{
        position: relative;
        z-index: 100;
      }
   }
   h2{
     font-size: 52px;
     color:rgba($color: #0071E7, $alpha: .9);
     font-weight: bold;
     text-shadow: 3px 3px 2px #77AAE0
   }
   h4{
     font-weight: 400;
     font-size: 20px;
     color:rgba($color: #04397F, $alpha: .65);
     margin-top:24px;
   }
   .btnWrap{
     margin-top:114px;
      .ant-btn{
        background: #007DFF;
        width: 180px;
        height: 44px;
        margin-right:24px;
        color: #ffffff;
      }
      .ant-btn.tosearch{
        background: transparent;
        border-color: #fff;
      }
   }
   .img1{
     position: absolute;
     top:-100px;
     width:750px;
     right:-200px;
   }
   .img2{
     width: 80%;
     max-width: 100%;
   }
}

.reportView {
  padding: 72px 0;
  .block {
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 109, 117, 0.06);
    padding: 32px 88px 16px 16px;
    height: 141px;
    position: relative;
    color: #001216;
    img {
      position: absolute;
      right: 0px;
      top: 4px;
      width: 66px;
    }
    h3 {
      margin: 8px 0 0 24px;
      font-size: 24px;
      display: flex;
      white-space: nowrap;
    }
    .dan {
      margin-left: 4px;
    }
    .tittle {
      display: flex;
      align-items: center;
    }
    .dot {
      background: radial-gradient(
        circle,
        rgba(144, 178, 255, 1),
        rgba(238, 243, 255, 0.15),
        rgba(255, 255, 255, 0)
      );
      width: 18px;
      height: 18px;
      border-radius: 100%;
      margin-right: 8px;
      display: block;
    }
    .blueDot {
      background: radial-gradient(
        circle,
        rgba(144, 178, 255, 1),
        rgba(238, 243, 255, 0.15),
        rgba(255, 255, 255, 0)
      );
    }
    .greenDot {
      background: radial-gradient(
        circle,
        rgba(42, 239, 239, 1),
        rgba(204, 251, 247, 0.15),
        rgba(255, 255, 255, 0)
      );
    }
    .orangeDot {
      background: radial-gradient(
        circle,
        rgba(255, 165, 103, 1),
        rgba(255, 243, 234, 0.15),
        rgba(255, 255, 255, 0)
      );
    }
  }
}

@media (min-width: 1200px) {
  .sliderWrap{
    height:750px;
    .blockInner{
      h2{
        font-size: 48px;
      }
      h4{
        font-size: 22px;
      }
    }
    .img1{
      width: 750px;
    }
    .btnWrap{
      margin-top:114px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px)  {
    .sliderWrap{
       height:auto;
      .blockInner{
        display: block;
        padding:24px 0 80px;
        margin-top: 84px;
        h2{font-size: 36px;}
        h4{font-size: 20px;    margin-top: 8px;}
      }
      .img1{
        width: 420px;
        position: absolute;
        margin-top:0;
        top: 20px;
      right: -140px;
      }
      .img3{
        width: 200px;
      }
      .btnWrap{
        margin-top:32px;
      }
    }
}

@media screen and (min-width: 10px) and (max-width: 767px) {
  .sliderWrap{
    height:auto;
    .slider-item{
    background-size: cover;
  }
    .blockInner{
       display: block;
       padding:16px 16px 64px;
       margin-top: 84px;
       h2{font-size: 24px;}
       h4{
         font-size: 16px;
         margin-top: 8px;
       }
    }
    .img1{
      width: 280px;
      position: absolute;
      margin-top:0px;
      top: 40px;
      right: -40px;
    }
    .img3{
      width: 150px;
    }
    .img2{width: 100%;}
    .btnWrap{
      margin-top: 20px!important;
      a{
        display: block;
        height: 32px;
      margin-top: 20px;
        button{
          float: left;
          width: 120px!important;
          height: 40px!important;
        }
      }
    }
    .ant-btn-lg{font-size: 14px;}
  }
  
}
</style>